export default {
  tree: {
    56: '',
    97: '',
  },
  cake: {
    56: '0x37781b87619722e1765dd4864894931bebD1BB3C',
    97: '',
  },
  masterChef: {
    56: '0x0a00A58f1291591babDddEb3624783C19ACe8006',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '0x3905752d2B75Fef31f845cd9aBe103a8BbF02383',
    97: '',
  },
  lotteryNFT: {
    56: '0x2843A4B1bC3d1A742Dd5a2899281e0e54e2Ee559',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '',
  },
}
